<script>
	import Checkbox from '@smui/checkbox';
	import List, { Item, Meta, Label } from '@smui/list';

	import { sortNotesFavourite } from '@stores/settings';
</script>

<List checklist>
	<Item>
		<Label>Show Favourites at the top of the list</Label>
		<Meta>
			<Checkbox bind:checked={$sortNotesFavourite} color="primary" />
		</Meta>
	</Item>
</List>
