<script>
	import { active } from 'tinro';
	import List, {
		Item,
		Text,
		Graphic,
		Separator,
		Subheader,
	} from '@smui/list';
	import H6 from '@smui/common/H6.svelte';

	import { labels } from '@stores/notes';
</script>

<style>
	:global(.mdc-drawer__content) {
		display: flex;
		flex-direction: column;
	}
	:global(.mdc-drawer__content__list) {
		flex-grow: 1;
		overflow-x: hidden;
		overflow-y: auto;
	}
</style>

<List class="mdc-drawer__content__list">
	<li>
		<a
			href="/"
			class="mdc-list-item"
			data-active-class="mdc-list-item--activated"
			use:active
			exact
		>
			<Graphic class="material-icons" aria-hidden="true">edit</Graphic>
			<Text>All Notes</Text>
		</a>
	</li>

	<Separator nav />

	<Subheader component={H6}>Labels</Subheader>
	{#each $labels as label}
		<li>
			<a
				href={`/label/${label}`}
				class="mdc-list-item"
				data-active-class="mdc-list-item--activated"
				use:active
			>
				<Graphic class="material-icons" aria-hidden="true">label</Graphic>
				<Text>{label}</Text>
			</a>
		</li>
	{:else}
		<Item>
			<Text>No Labels</Text>
		</Item>
	{/each}
</List>
<List dense>
	<Separator nav />

	<li>
		<a class="mdc-list-item" href="/settings/">
			<Graphic class="material-icons" aria-hidden="true">settings</Graphic>
			<Text>Settings</Text>
		</a>
	</li>
</List>
