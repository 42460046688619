<script>
	import Menu from '@smui/menu';
	import List, { Item, Meta, Label, Text } from '@smui/list';

	import { sortNotesOrder } from '@stores/settings';

	let menu;
	const options = [
		{ text: 'Modified Date (Newest First)', value: 'date-asc' },
		{ text: 'Modified Date (Oldest First)', value: 'date-dsc' },
		{ text: 'Title (A-Z)', value: 'title-asc' },
		{ text: 'Title (Z-A)', value: 'title-dsc' },
	];
	const handleSelection = (value) => sortNotesOrder.set(value);

	$: selection = options.find((item) => item.value === $sortNotesOrder);
</script>

<List>
	<Item on:SMUI:action={() => menu.setOpen(true)}>
		<Label>Sort Notes by:</Label>
		<Meta>
			<Text>{selection.text}</Text>
		</Meta>
	</Item>
	<Menu bind:this={menu} anchorCorner="BOTTOM_LEFT">
		<List>
			{#each options as item}
				<Item on:SMUI:action={() => handleSelection(item.value)}>
					<Text>{item.text}</Text>
				</Item>
			{/each}
		</List>
	</Menu>
</List>
