import { get, writable } from 'svelte/store';
import { router } from 'tinro';

import { user as authUser } from '@stores/auth';
import { firestore } from '@app/firebase';
import { getTitle } from '@app/utils';

let user;
const labels = writable([]);
const loading = writable(true);
const notes = writable([]);

const returnNoteObject = ({
	created,
	date,
	favourite,
	id,
	text,
	title,
}) => {
	if (!id) {
		// eslint-disable-next-line no-console
		console.error(`An id needs to be provided. "${id}" is not valid`);
		return null;
	}

	const newNote = {
		created: created || +new Date(),
		date: date || +new Date(),
		favourite: favourite || false,
		id,
		text: text || '',
		title: title || getTitle(text),
	};

	return newNote;
};

/**
 * Create a note based on the text provided, and navigate to the new note.
 * If an Untitled note already exists, navigate to that note
 * @param {string} [text] - Initial text to use for the note
 */
const createNote = (text = '') => {
	const untitledNote = get(notes).find((note) => note.text === '');

	if (untitledNote) {
		router.goto(`/note/${untitledNote.id}`);
	} else {
		const newNote = firestore.collection(user.uid).doc();
		const value = returnNoteObject({
			id: newNote.id,
			text,
		});

		newNote.set(value).then(() => router.goto(`/note/${value.id}`));
	}
};

/**
 * Delete a note and navigate back to Home
 * @param {object} [note]
 */
const deleteNote = (note) => {
	firestore.collection(user.uid).doc(note.id)
		.delete()
		// .then(() => snackbar.showMessage({
		// 	actionFunction() {
		// 		firestore.collection(user.uid).doc(note.id).set(note);
		// 	},
		// 	actionText: 'Undo',
		// 	message: `"${note.title}" has been deleted`,
		// }));

		router.goto('/');
};

/**
 * Toggle favourite on a note
 * @param {object} [note]
 */
const favouriteNote = (note) => {
	firestore.collection(user.uid).doc(note.id)
		.update({
			favourite: !note.favourite,
		})
		// .then(() => snackbar.showMessage({
		// 	actionFunction() {
		// 		firestore.collection(user.uid).doc(note.id).set(note);
		// 	},
		// 	actionText: 'Undo',
		// 	message: `"${note.title}" has been ${note.favourite ? 'removed' : 'added'} as a favourite`,
		// }));
};

	/**
	 * Creates notes from an array
	 * @param {array} [listOfNotes]
	 */
	const importNotes = (listOfNotes) => {
		const batch = firestore.batch();

		listOfNotes.forEach((note) => {
			const newNote = firestore.collection(user.uid).doc();
			const value = returnNoteObject({
				...note,
				id: newNote.id,
			});

			batch.set(newNote, value);
		});

		batch.commit()
			// .then(() => snackbar.showMessage({
			// 	message: `${listOfNotes.length} note${listOfNotes.length === 1 ? ' has' : 's have'} been imported`,
			// }));
	};

/**
 * Update the labels on a note
 * @param {array} newLabels
 * @param {object} [note]
 */
const updateLabels = (newLabels, note) => {
	firestore.collection(user.uid).doc(note.id).update({
		labels: newLabels,
	});
};

/**
 * Update the text on a note
 * @param {string} text
 * @param {object} [note]
 */
const updateNote = (text, note) => {
	firestore.collection(user.uid).doc(note.id).update({
		date: +new Date(),
		text,
		title: getTitle(text),
	});
};

authUser.subscribe(value => {
	user = value;
	if (user) {
		firestore.collection(user.uid)
			.onSnapshot((snapshot) => {
				const authNotes = snapshot.docs.map((doc) => doc.data());
				const authLabels = authNotes.map((note) => note.labels).filter(Boolean).flat();

				loading.set(false);
				notes.set(authNotes);
				labels.set([...new Set(authLabels)].sort());
			});
	} else if (user !== null) {
		loading.set(false);
		labels.set([]);
		notes.set([]);
	}
})


export {
	createNote,
	deleteNote,
	favouriteNote,
	importNotes,
	labels,
	loading,
	notes,
	updateLabels,
	updateNote,
};
