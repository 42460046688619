<script>
	import { router } from 'tinro';
	import IconButton from '@smui/icon-button';
	import TopAppBar, { FixedAdjust, Row, Section, Title } from '@smui/top-app-bar';
	import H1 from '@smui/common/H1.svelte';

	const Adjust = FixedAdjust;
	const backPath = $router.path !== $router.from ? $router.from : '/';

	export let title = '';
</script>

<style>
	:global(.content) {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow-x: hidden;
		overflow-y: auto;
	}
</style>

<svelte:head>
	<title>{title} | NoteMe</title>
</svelte:head>

<TopAppBar variant="fixed" color="primary">
	<Row>
		<Section>
			<slot name="icons-start">
				<a href={backPath}>
					<IconButton class="material-icons" aria-label="Back">arrow_back</IconButton>
				</a>
			</slot>
			<Title component={H1}>{title}</Title>
		</Section>
		<Section align="end" toolbar>
			<slot name="icons-end"></slot>
		</Section>
	</Row>
</TopAppBar>

<main class="content" use:Adjust>
	<slot />
</main>
