import { writable } from 'svelte/store';

const localStorageSubscribe = (key, value) => localStorage.setItem(key, JSON.stringify(value));

export const sortNotesFavourite = writable(JSON.parse(localStorage.getItem('sort-notes-favourite')) || false);
export const sortNotesOrder = writable(JSON.parse(localStorage.getItem('sort-notes-order')) || 'date-asc');
export const updateAvailable = writable(false);

sortNotesFavourite.subscribe((value) => localStorageSubscribe('sort-notes-favourite', value));
sortNotesOrder.subscribe((value) => localStorageSubscribe('sort-notes-order', value));
