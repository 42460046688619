<script>
	import DOMPurify from 'dompurify';
	import marked from 'marked'
	import { router } from 'tinro';
	import Fab, { Icon } from '@smui/fab';
	import IconButton from '@smui/icon-button';

	import Page from '@components/Page';
	import { deleteNote, favouriteNote, notes, updateNote } from '@stores/notes';

	const { params: { id } } = router.meta();
	let changedText = '';
	let edit = false;

	$: currentNote = $notes.find((note) => note.id === id);
	$: if (changedText !== '') {
		// console.log(changedText);
		updateNote(changedText, currentNote);
		changedText = '';
	}
	$: if (currentNote?.text === '') edit = true;
</script>

<style>
	.content {
		width: 100%;
		flex-grow: 1;
		padding: 16px;
		padding-bottom: 86px;
		overflow-y: auto;
	}

	.textarea {
		resize: none;
		border: none;
		font-size: 1.2em;
	}
	.textarea:focus {
		outline: none;
	}

	:global(.marked h1) {
		font-size: '1.75em';
	}
	:global(.marked ol, .marked ul) {
		padding-left: 32px;
	}
	:global(.marked h1, .marked h2, .marked h3, .marked h4, .marked h5, .marked h6, .marked p, .marked ol, .marked ul) {
		margin: 0;
		margin-bottom: 16px;
	}

	:global(.fab) {
		position: absolute;
		bottom: 16px;
		right: 16px;
	}
</style>

<Page title={currentNote?.title || 'Note'}>
	<span slot="icons-end" class="flex">
		{#if currentNote}
			<IconButton
				class="material-icons"
				aria-label={currentNote.favourite ? 'Unfavourite' : 'Favourite'}
				bind:pressed={currentNote.favourite}
				on:click={() => favouriteNote(currentNote)}
			>
				<Icon class="material-icons" on>star</Icon>
				<Icon class="material-icons">star_border</Icon>
			</IconButton>
			<IconButton
				class="material-icons"
				aria-label={`${currentNote?.labels?.length !== 0 ? 'Change' : 'Add'} Labels`}
			>
				label
			</IconButton>
			<IconButton
				class="material-icons"
				aria-label="Search Notes"
				on:click={() => deleteNote(currentNote)}
			>
				delete
			</IconButton>
		{/if}
	</span>

	{#if currentNote}
		{#if edit}
			<textarea
				class="content textarea"
				value={currentNote.text}
				on:change={(event) => changedText = event.target.value}
			/>
		{:else}
			<div class="content marked">
				{@html DOMPurify.sanitize(marked(currentNote.text))}
			</div>
		{/if}
	{:else}
		<span class="content">Loading note. Please wait</span>
	{/if}

	<Fab
		class="fab"
		color="primary"
		exited={!currentNote}
		on:click={() => edit = !edit}
	>
		<Icon class="material-icons">{edit ? 'save' : 'edit'}</Icon>
	</Fab>
</Page>
