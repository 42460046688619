<script>
	import { get } from 'svelte/store';
	import { router } from 'tinro';
	import Button from '@smui/button';
	import List, { Item, Meta, Text } from '@smui/list';
	import Fab, { Icon } from '@smui/fab';

	import Container from '@components/Container';
	import { signIn, user } from '@stores/auth';
	import { createNote, loading, notes } from '@stores/notes';
	import { sortNotesOrder, sortNotesFavourite } from '@stores/settings';

	const { params: { label } } = router.meta();
	// console.log(label);

	const sortNoteOrderFunction = {
		'date-asc': (a, b) => b.date - a.date,
		'date-dsc': (a, b) => a.date - b.date,
		'title-asc': (a, b) => a.text.localeCompare(b.text),
		'title-dsc': (a, b) => b.text.localeCompare(a.text),
	}[get(sortNotesOrder)];
	const sortNotesFavouriteFunction = (a, b) => {
		if (get(sortNotesFavourite)) {
			if (a.favourite === b.favourite) return 0;
			if (a.favourite) return -1;
			return 1;
		}
		return 0;
	};

	$: sortedNotes = $notes
		.sort(sortNoteOrderFunction)
		.sort(sortNotesFavouriteFunction)
		.filter((note) => label ? note?.labels?.includes(label) : true);
</script>

<style>
	:global(.fab) {
		position: absolute;
		bottom: 16px;
		right: 16px;
	}
</style>

<Container title={label || 'All Notes'}>
	{#if !$user && !$loading}
		<List nonInteractive>
			<Item>
				<Text>Hello and welcome to NoteMe</Text>
			</Item>
			<Item>
				<Text>Please sign in below to be able to store and edit your markdown notes from any device</Text>
			</Item>
			<Item>
				<Button variant="raised" on:click={signIn}>
					Sign in with Google
				</Button>
			</Item>
		</List>
	{:else}
		<List>
			{#each sortedNotes as note}
				<li>
					<a class="mdc-list-item" href={`/note/${note.id}`}>
						<Text>{note.title}</Text>
						<Meta class="flex">
							{#if note.favourite}
								<span class="material-icons primary">star</span>
							{/if}
							<span class="material-icons">keyboard_arrow_right</span>
						</Meta>
					</a>
				</li>
			{:else}
				{#if $loading}
					<Item>
						<Text>Loading, please wait while we gather your notes</Text>
					</Item>
				{:else}
					<Item>
						<Text>No notes found</Text>
					</Item>
				{/if}
			{/each}
		</List>
	{/if}

	<Fab
		class="fab"
		color="primary"
		exited={!$user && $loading}
		on:click={() => createNote('')}
	>
		<Icon class="material-icons">add</Icon>
	</Fab>
</Container>
